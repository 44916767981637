import React from 'react'

export default function CompanyHero() {
    return (
        <>
            <div
                className="fugu--hero-section3 "
                style={{ backgroundImage: `url('assets/images/network-bg.png')`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "50% 50%" }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div
                                className="video-text"
                                style={{
                                    visibility: "visible",
                                    animationName: "fadeInUpX",
                                    height: "500px",
                                }}
                            >
                                <div className="pt-5">
                                    <h1
                                        className="wow fadeInUpX text-white"
                                        data-wow-delay="0s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0s",
                                            animationName: "fadeInUpX",
                                        }}
                                    >
                                        Apps. <br />
                                        Businesses. <br />
                                        Communities.
                                    </h1>
                                </div>
                            </div>
                            <video muted autoPlay loop id="myVideo" className="video-2 d-lg-block d-md-block d-sm-none d-none">
                                <source src="assets/images/network_video.mp4" type="video/mp4" />
                            </video>
                            <video muted autoPlay loop id="myVideo" className="video-2 d-lg-none d-md-none d-sm-block d-block">
                                <source src="assets/images/network_video_mobile.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
