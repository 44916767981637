import React from 'react'

export default function Believe() {
    return (
        <>
            <div className="fugu--feature-section fugu--section-padding2">
                <div className="container">
                    <div className="fugu--section-title2 pt-5">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="fugu--default-content content-sm">
                                    <h2>Our strength is to change the path of our creativity trade NET</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-lg-8 ">
                                <div className="fugu--default-content">
                                    <p>
                                        NETWORK ecosystem is connected with real world applications including travelling platforms, e-commerce portals, games, media platforms and dozens of others offering unique utility for NET Coin.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-md-6">
                            <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="fugu--iconbox-thumb">
                                    <img src="assets/images/safe_and_secure.png" width="50" alt="" />
                                </div>
                                <div className="fugu--iconbox-data">
                                    <h4>Safe and secure</h4>
                                    <p>NET are safe when bought or sold on reputable exchanges, &amp; they cannot be stolen if users properly secure.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay=".15s" style={{ visibility: 'visible', animationDelay: '0.15s', animationName: 'fadeInUpX' }}>
                                <div className="fugu--iconbox-thumb">
                                    <img src="assets/images/complete_transparency.png" width="50" alt="" />
                                </div>
                                <div className="fugu--iconbox-data">
                                    <h4>Complete transparency</h4>
                                    <p>Transparency in a process involves it being completely visible and open to scrutiny, so that it's clear.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="fugu--iconbox-thumb">
                                    <img src="assets/images/community_empowerment.png" width="50" alt="" />
                                </div>
                                <div className="fugu--iconbox-data pb-2">
                                    <h4>Trusted sanctuary</h4>
                                    <p>Your trusted sanctuary in the digital realm, safeguarding every transaction with unparalleled security</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-md-6">
                            <div className='row'>
                                <div className="col-xl-6 col-md-6">
                                    <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay=".15s" style={{ visibility: 'visible', animationDelay: '0.15s', animationName: 'fadeInUpX' }}>
                                        <div className="fugu--iconbox-data">
                                            <h4>$100</h4>
                                            <p>of Asset Secured MarketCap</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6">
                                    <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay=".15s" style={{ visibility: 'visible', animationDelay: '0.15s', animationName: 'fadeInUpX' }}>
                                        <div className="fugu--iconbox-data">
                                            <h4>10+</h4>
                                            <p>Ecosystem Partners</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6">
                                    <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay=".15s" style={{ visibility: 'visible', animationDelay: '0.15s', animationName: 'fadeInUpX' }}>
                                        <div className="fugu--iconbox-data">
                                            <h4>100%</h4>
                                            <p>Asset Surety</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6">
                                    <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay=".15s" style={{ visibility: 'visible', animationDelay: '0.15s', animationName: 'fadeInUpX' }}>
                                        <div className="fugu--iconbox-data">
                                            <h4>1M+</h4>
                                            <p>Unique Users Monthly</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
