import React from "react";

export default function Advantage() {
  return (
    <>
      <div className="fugu--artwork-section">
        <div id="fugu--counter2" />
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <div className="fugu--default-content content-black">
                <h4>Advantages</h4>
                <h2>Everything you want. All in one.</h2>
                <h3>Permissionless. Frictionless. Boundless.</h3>
                <div className="fugu--counter-wra fugu--counter-wrap2 mt-5">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="fugu--counter-data">
                        <div className="row justify-content-center">
                          <div className="col-lg-4 col-sm-12 col-12 text-center">
                            <img src="./assets/images/icon/decentralized.png" className="m-2" width="50" height="50" alt="" />
                          </div>
                          <div className="col-lg-8 col-sm-12 col-12 text-center">
                            <p className="text-lg-start text-sm-center text-center m-2">
                              Decentralized Ecosytem Governanc
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="fugu--counter-data">
                        <div className="row">
                          <div className="col-lg-4 col-sm-12 col-12 text-center">
                            <img src="./assets/images/icon/secure_network.png" className="m-2" width="50" height="50" alt="" />
                          </div>
                          <div className="col-lg-8 col-sm-12 col-12 text-center">
                            <p className="text-lg-start text-sm-center text-center m-2">Secure <br /> Network</p >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="fugu--counter-data">
                        <div className="row">
                          <div className="col-lg-4 col-sm-12 col-12 text-center">
                            <img src="./assets/images/icon/scalable.png" className="m-2" width="50" height="50" alt="" />
                          </div>
                          <div className="col-lg-8 col-sm-12 col-12 text-center">
                            <p className="text-lg-start text-sm-center text-center m-2">
                              Scalable <br /> Infrastructure
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="fugu--counter-data">
                        <div className="row">
                          <div className="col-lg-4 col-sm-12 col-12 text-center">
                            <img src="./assets/images/icon/simple_integration.png" className="m-2" width="50" height="50" alt="" />
                          </div>
                          <div className="col-lg-8 col-sm-12 col-12 text-center">
                            <p className="text-lg-start text-sm-center text-center m-2">
                              Simple <br />
                              Integration
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="fugu--counter-data">
                        <div className="row">
                          <div className="col-lg-4 col-sm-12 col-12 text-center">
                            <img src="./assets/images/icon/affordable.png" className="m-2" width="50" height="50" alt="" />
                          </div>
                          <div className="col-lg-8 col-sm-12 col-12 text-center">
                            <p className="text-lg-start text-sm-center text-center m-2">
                              Affordable  On-Chain Storage
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="fugu--counter-data">
                        <div className="row">
                          <div className="col-lg-4 col-sm-12 col-12 text-center">
                            <img src="./assets/images/icon/fast_transactions.png" className="m-2" width="50" height="50" alt="" />
                          </div>
                          <div className="col-lg-8 col-sm-12 col-12 text-center">
                            <p className="text-lg-start text-sm-center text-center m-2">
                              Fast <br /> Transactions
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="fugu--counter-data">
                        <div className="row">
                          <div className="col-lg-4 col-sm-12 col-12 text-center">
                            <img src="./assets/images/icon/low_fees.png" className="m-2" width="50" height="50" alt="" />
                          </div>
                          <div className="col-lg-8 col-sm-12 col-12 text-center">
                            <p className="text-lg-start text-sm-center text-center m-2">
                              Low <br />
                              Gas Fees
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="fugu--counter-data">
                        <div className="row">
                          <div className="col-lg-4 col-sm-12 col-12 text-center">
                            <img src="./assets/images/network_community.png" className="m-2" width="50" height="50" alt="" />
                          </div>
                          <div className="col-lg-8 col-sm-12 col-12 text-center">
                            <p className="text-lg-start text-sm-center text-center m-2">
                              Networking <br /> Community
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
