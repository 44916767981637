import React from "react";

export default function Ecoosystem() {
  return (
    <>
      <div className="fugu--vision-section py-5">
        <div className="container">
          <div className="row py-5">
            <div className="col-xl-4 col-lg-4">
              <img
                src="assets/images/dapps_section_img.png" className="w-100"
                alt=""
              />
            </div>
            <div className="col-xl-6 offset-xl-2 col-lg-7 offset-lg-1 d-flex align-items-center">
              <div className="fugu--default-content content-sm">
                <h3 className="text-white">
                  Ecosystem
                </h3>
                <h1 className="text-white">
                  dApps.
                  Businesses. Communities.
                </h1>
                <div className="fugu--tags">
                  <ul>
                    <li><a href="#">NFTs art</a></li>
                    <li><a href="#">Cryptocurrency</a></li>
                    <li><a href="#">Digital assets</a></li>
                    <li><a href="#">Analysis</a></li>
                    <li><a href="#">Earning</a></li>
                    <li><a href="#">Creative works</a></li>
                  </ul>
                </div>
                <div className="fugu--btn-wrap">
                  <a className="fugu--btn bg-gradient" href="ecosystem">
                    Join our ecosystem
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
