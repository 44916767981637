import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import ResearchHero from './ResearchHero'

export default function Research() {
    return (
        <>
            <Header />
            <ResearchHero />
            <div className="fugu--feature-section fugu--section-padding2">
                <div className="container">
                    <div className="fugu--section-title2">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="fugu--default-content content-sm">
                                    <h2>Unlocking new pathways for creative commerce through the power of NET.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-md-6">
                            <a href="https://networkchain.gitbook.io/research-paper/" target='_blank'>
                                <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                    <img src="assets/images/safe_and_secure.png" width={100} alt="" />
                                    <div className="fugu--iconbox-data">
                                        <h4>Research paper</h4>
                                        <p>The network marketing industry has long sought solutions to various challenges such as lack of transparency, inefficient operations, and limited scalability.</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <a href=" https://networkchain.gitbook.io/technical-paper/" target='_blank'>
                                <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay=".15s" style={{ visibility: 'visible', animationDelay: '0.15s', animationName: 'fadeInUpX' }}>
                                    <img src="assets/images/complete_transparency.png" width={100} alt="" />
                                    <div className="fugu--iconbox-data">
                                        <h4>Technical Paper</h4>
                                        <p>This technical paper provides an in-depth exploration of Network Chain, a blockchain platform tailored for the unique requirements of network marketing companies.</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <a href="https://networkchain.gitbook.io/networkchain/" target='_blank'>
                                <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay=".25s" style={{ visibility: 'visible', animationDelay: '0.25s', animationName: 'fadeInUpX' }}>
                                    <img src="assets/images/creative_team.png" width={100} alt="" />
                                    <div className="fugu--iconbox-data">
                                        <h4>Whitepaper</h4>
                                        <p>The Network Chain Whitepaper introduces a groundbreaking blockchain platform specifically designed to cater to the needs of network marketing companies.</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <a href="https://networkchain.gitbook.io/developer-documentation" target='_blank'>
                                <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay=".25s" style={{ visibility: 'visible', animationDelay: '0.25s', animationName: 'fadeInUpX' }}>
                                    <img src="assets/images/creative_team.png" width={100} alt="" />
                                    <div className="fugu--iconbox-data">
                                        <h4>Developer Documentation</h4>
                                        <p>We are currently working on comprehensive developer documentation to provide detailed technical guidance and resources for building on the Network Chain platform. </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
