import React from 'react'

export default function WhyChoosNetPay() {
    return (
        <>
            <div className="fugu--content-section pt-5">
                <div className="container">
                    <div className="fugu--content-top">
                        <div className="row">
                            <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                                <div className="fugu--content-thumb">
                                    <img className="wow fadeInUpX w-100" data-wow-delay=".10s" src="assets/images/benifits_of_netter.png" alt="" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUpX' }} />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="fugu--default-content">
                                    <h2>Benefit Netsoft</h2>
                                    <div className="fugu--meta">
                                        <ul>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />Increased Efficiency: By leveraging NetSoft's technology solutions, businesses can streamline operations, automate processes, and improve overall efficiency. Whether it's optimizing workflows or reducing manual tasks, NetSoft's solutions help businesses operate more smoothly and effectively.
                                            </li>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />
                                                Increased Efficiency: By leveraging NetSoft's technology solutions, businesses can streamline operations, automate processes, and improve overall efficiency. Whether it's optimizing workflows or reducing manual tasks, NetSoft's solutions help businesses operate more smoothly and effectively.
                                            </li>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />Cost Savings: NetSoft's solutions are designed to deliver maximum value at competitive prices. By optimizing resources, reducing waste, and improving productivity, businesses can achieve significant cost savings over time, ensuring a strong return on investment.

                                            </li>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />Cost Savings: NetSoft's solutions are designed to deliver maximum value at competitive prices. By optimizing resources, reducing waste, and improving productivity, businesses can achieve significant cost savings over time, ensuring a strong return on investment.
                                            </li>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />Future-Proofing: Future-proof your business with NetSoft's scalable and flexible solutions. By investing in technologies that can adapt to changing market dynamics and emerging trends, businesses can position themselves for long-term success and sustainability.
                                            </li>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />Peace of Mind: With NetSoft as your technology partner, you can have peace of mind knowing that your projects are in capable hands. From initial concept to final delivery, NetSoft's team of experts is committed to your success, providing support, guidance, and expertise every step of the way.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
