import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import EcosystemHero from './EcosystemHero'
import EcosystemApp from './EcosystemApp'

export default function Ecosystem() {
    return (
        <>
            <Header />
            <EcosystemHero />
            <EcosystemApp/>
            <Footer />
        </>
    )
}
