import React from 'react'

export default function DecentralizedHero() {
    return (
        <>
            <div
                className="fugu--hero-section3"
                style={{backgroundImage:`url('assets/images/network-bg.png')`, backgroundRepeat:"no-repeat", backgroundSize:"cover",backgroundPosition:"50% 50%"}}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="fugu--hero-conten fugu--hero-content3">
                                <h1
                                    className="wow fadeInUpX text-white"
                                    data-wow-delay="0s"
                                    style={{
                                        visibility: "visible",
                                        animationDelay: "0s",
                                        animationName: "fadeInUpX",
                                    }}
                                >
                                    Welcome
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div
                                className="video-text"
                                style={{
                                    visibility: "visible",
                                    animationName: "fadeInUpX",
                                    height: "500px",
                                }}
                            >
                                <div className="pt-5">
                                    <p
                                        className="wow fadeInUpX text-white text-start"
                                        data-wow-delay="0.15s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.15s",
                                            animationName: "fadeInUpX",
                                        }}
                                    >
                                        Decentralized P2P network,<br />
                                        no downtime, fast/cheap txs, <br /> op/zk combination.
                                    </p>
                                    <div
                                        className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX"
                                        data-wow-delay="0.25s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.25s",
                                            animationName: "fadeInUpX",
                                        }}
                                    >
                                        <a className="fugu--btn bg-blue active" href="https://medium.com/@netchainofficial/become-a-miner-9860c1544dc1" target='_blank'>
                                            Becomer Miner
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <video muted autoPlay loop id="myVideo" className="video-2">
                                <source src="assets/images/hero.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
