import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import CEGHero from './CEGHero'
import WhatWho from './WhatWho'
import Why from './Why'
import Decenterlize from './Decenterlize'
export default function CEG() {
    return (
        <>
            <Header />
            <CEGHero />
            <WhatWho />
            <Decenterlize />
            <Why />
            <Footer />
        </>
    )
}
