import React from 'react'

export default function NetFxHero() {
    return (
        <>
            <div className="fugu--hero-section" style={{backgroundImage:`url('assets/images/network-bg.png')`, backgroundRepeat:"no-repeat", backgroundSize:"cover",backgroundPosition:"50% 50%"}}>
                <div id="fugu--counter" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="fugu--hero-content">
                                <h1 className="wow fadeInUpX" data-wow-delay="0s">Empowering Forex Trading with Network Chain</h1>
                                <p className="wow fadeInUpX" data-wow-delay="0.10s">NETFX is an innovative platform built on the Network Chain blockchain network, specifically designed to revolutionize the forex trading industry. With its cutting-edge technology and robust features, NETFX offers a seamless and efficient trading experience for forex traders worldwide.
                                </p>
                                <div className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX" data-wow-delay="0.20s">
                                    <a className="fugu--btn bg-gray active" href="/coming-soon">Visit</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <img src="assets/images/net_fx_phn.png" className='w-100' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
