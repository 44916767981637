import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import DecentralizedHero from './DecentralizedHero'
import Sequencer from './Sequencer'
import Redacted from './Redacted'
import Architecture from './Architecture'
import BecomeSequencer from './BecomeSequencer'
import SequencerNode from './SequencerNode'
import Benefits from './Benefits'
import Faq from './Faq'

export default function Decentralized() {
    return (
        <>
            <Header />
            <DecentralizedHero />
            <Sequencer />
            <Redacted />
            <Architecture />
            <BecomeSequencer />
            <SequencerNode />
            <Benefits />
            <Faq/>
            <Footer />
        </>
    )
}
