import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import MineNetworkHero from './MineNetworkHero'
import MineNetworFeture from './MineNetworFeture'

export default function MineNetwork() {
  return (
      <>
          <Header />
          <MineNetworkHero />
          <MineNetworFeture/>
          <Footer/>
      </>
  )
}
