import React from 'react'

export default function NetBox() {
  return (
    <>
      <div className="fugu--content-section">
        <div className="container">
          <div className="fugu--content-bottom">
            <div className="row align-items-center">
              <div className="col-xl-6 d-flex align-items-center">
                <img src="assets/images/net_box.svg" className='w-100' alt="" />
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2>NetBox</h2>
                  <p>NetBOX is a unique product within the Network Chain ecosystem, offering a limited supply of 10,000 tokens. Holders of NetBOX tokens gain exclusive access to support and resources from the Network Chain organization. This includes assistance with product development, listing on exchanges, technical support, and marketing promotion events. By holding NetBOX tokens, users can leverage unlimited support to build and grow their projects within the network.</p>
                  <div className="fugu--btn-wrap">
                    <a className="fugu--btn bg-blue" href="/net-box">Learn More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
