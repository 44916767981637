import React from 'react'

export default function Benefits() {
    return (
        <>
            <div className="section bg-warning-200 fugu-section-padding3">
                <div className="container">
                    <div className="fugu-section-title">
                        <h2>Secure solutions for your digital assets and money</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-icon2">
                                    <img src="assets/images/all-img/v2/icon1.png" alt="" />
                                </div>
                                <div className="fugu-iconbox-data2">
                                    <h4>User friendly dashboard</h4>
                                    <p>Every user controls the data they wish to see as well as the dashboard's structure.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0.20s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-icon2">
                                    <img src="assets/images/all-img/v2/icon2.png" alt="" />
                                </div>
                                <div className="fugu-iconbox-data2">
                                    <h4>Secure and fast payments</h4>
                                    <p>Every user controls the data they wish to see as well as the dashboard's structure.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
