import React, { useEffect, useState } from "react";
import CountdownTimer from "../Comman/CountdownTimer";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

export default function Web3() {
  const calculateTimeLeft = () => {
    const today = new Date();
    const targetDate = new Date(today);
    targetDate.setDate(today.getDate() + 180); // Adding 180 days to today's date
    const difference = targetDate - today;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{' '}
      </span>
    );
  });

  return (
    <>
      <div className="fugu--feature-section fugu--section-padding2">
        <div className="container">
          <div className="fugu--section-title2">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="fugu--default-content content-sm">
                  <h2>Web3</h2>
                </div>
                <div className="fugu--default-content">
                  <p>
                    Accelerating the transition to Web3 for an inclusive,
                    accessible, and decentralized world
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-10 col-md-12">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--iconbox-thumb">
                  <img src="assets/images/developers.png" alt="" />
                </div>
                <div className="fugu--iconbox-data">
                  <p>Networkchain Live soon</p>
                  <h3 className="text-white"> <CountdownTimer targetDate={'2024-09-23'} />
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-md-6">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                data-wow-delay=".15s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.15s",
                  animationName: "fadeInUpX",
                }}
              >
                {/* <div className="fugu--iconbox-thumb">
                    <img src="assets/images/community.png" alt="" />
                  </div> */}
                <div className="fugu--iconbox-data d-flex justify-content-center">
                  <div style={{ width: 200, height: 200 }}>
                    <CircularProgressbarWithChildren value={60} styles={{
                      path: {
                        // Path color
                        stroke: '#fe4b00',
                      },
                    }}>
                      <img style={{ width: 80, marginTop: -5 }} src="assets/images/community.png" alt="doge" />
                      <div className="text-orange" style={{ fontSize: 18, marginTop: 5 }}>
                        <strong >60K</strong> Miner
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                  {/* <p>Join a thriving future-forward community</p> */}
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-md-6">
              <div
                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                data-wow-delay=".15s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.15s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu--iconbox-data d-flex justify-content-center">
                  <div style={{ width: 200, height: 200 }}>
                    <CircularProgressbarWithChildren value={10} styles={{
                      path: {
                        // Path color
                        stroke: '#fe4b00',
                      },
                    }}>
                      <img style={{ width: 80, marginTop: -5 }} src="assets/images/developers.png" alt="doge" />
                      <div className="text-orange" style={{ fontSize: 18, marginTop: 5 }}>
                        <strong >10</strong> dApps
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                  {/* <p>Join a thriving future-forward community</p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-5 col-md-6">
              <a href="https://networkchain.gitbook.io/developer-documentation" target="">
                <div
                  className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                  data-wow-delay="0s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0s",
                    animationName: "fadeInUpX",
                  }}
                >
                  <div className="fugu--iconbox-thumb">
                    <img src="assets/images/developers.png" alt="" />
                  </div>
                  <div className="fugu--iconbox-data">
                    <h4>Developers</h4>
                    <p>Bring your project idea to life easier</p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-xl-5 col-md-6">
              <a href="/community" target="_blank">
                <div
                  className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                  data-wow-delay=".15s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.15s",
                    animationName: "fadeInUpX",
                  }}
                >
                  <div className="fugu--iconbox-thumb">
                    <img src="assets/images/community.png" alt="" />
                  </div>
                  <div className="fugu--iconbox-data">
                    <h4>Community</h4>
                    <p>Join a thriving future-forward community</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div >
      </div >
    </>
  );
}
