import React from 'react'

export default function NetPay() {
  return (
    <>
      <div className="fugu--content-section pt-5">
        <div className="container">
          <div className="fugu--content-top">
            <div className="row align-items-center">
              <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                <img src="assets/images/net_pay.svg" className='w-100' alt="" />
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <h2>NetPay</h2>
                  <p>NetPay is a revolutionary payment solution within the Network Chain ecosystem, designed to streamline transactions for network marketers. It offers fast, secure, and cost-effective payment processing, allowing users to send and receive payments seamlessly. With features like built-in security protocols and user-friendly interfaces, NetPay ensures a smooth and efficient payment experience for all users.</p>
                </div>
                <div className="fugu--btn-wrap">
                  <a className="fugu--btn bg-blue" href="/net-pay">Learn more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
