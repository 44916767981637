import React from 'react'

export default function Minig() {
    return (
        <>
            <div className="fugu--feature-section fugu--section-padding2">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-md-6">
                            <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="fugu--iconbox-thumb">
                                    <img src="assets/images/limited_token_supply.png" width="70" alt="" />
                                </div>
                                <div className="fugu--iconbox-data">
                                    <h4>Limited Token Supply</h4>
                                    <p>NBOX has a limited token supply of only 10,000 tokens, making it a scarce and valuable asset within the Network Chain ecosystem.</p>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="fugu--iconbox-thumb">
                                    <img src="assets/images/exclusive_benefits.png" width="60" alt="" />
                                </div>
                                <div className="fugu--iconbox-data">
                                    <h4>Exclusive Benefits</h4>
                                    <p>Holders of NBOX tokens gain exclusive benefits and privileges within the Network Chain community, including access to specialized support services, priority listing assistance, and technical guidance.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="fugu--iconbox-thumb">
                                    <img src="assets/images/community_empowerment.png" width="70" alt="" />
                                </div>
                                <div className="fugu--iconbox-data">
                                    <h4>Community Empowerment</h4>
                                    <p>NBOX token holders play a crucial role in shaping the future of Network Chain by actively participating in governance decisions, proposing new initiatives, and contributing to the platform's development.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
