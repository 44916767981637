import React from 'react'

export default function WorkWithUs() {
  return (
      <>
      <div className="section fugu-section-padding">
  <div className="container">
    <div className="row">
      <div className="col-xl-7 col-lg-6">
        <div className="fugu-innovative-services-thumb wow fadeInUpX" data-wow-delay=".10s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUpX'}}>
          <img src="assets/images/all-img/career/thumb.png" alt="" />
        </div>
      </div>
      <div className="col-xl-5 col-lg-6 col-md-11 d-flex align-items-center">
        <div className="fugu-default-content">
          <h2>Want to work with us? We provide jobs to the qualified people</h2>
          <p>Our company does a lot of work and needs qualified people to perform these tasks sincerly. We value talented people.</p>
          <p>If you are wondering if your work is meaningful, we help you understand how your work brings meaning and purpose to your life. We work together with sincerity.</p>
        </div>
      </div>
    </div>
  </div>
</div>

      </>
  )
}
