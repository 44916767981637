import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import CommunityHero from './CommunityHero'
import CommunityApp from './CommunityApp'

export default function Community() {
  return (
      <>
          <Header />
          <CommunityHero />
          <CommunityApp/>
          <Footer/>
      </>
  )
}
