import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import NetterHero from './NetterHero'
import NetterFeature from './NetterFeature'
import WhyChoosNetter from './WhyChoosNetter'

export default function Netter() {
    return (
        <>
            <Header />
            <NetterHero />
            <NetterFeature /> 
            <WhyChoosNetter />
            <Footer />
        </>
    )
}
