import React from 'react'

export default function NetFX() {
    return (
        <>
            <div className="fugu--content-section">
                <div className="container">
                    <div className="fugu--content-bottom">
                        <div className="row align-items-center">
                            <div className="col-xl-6 d-flex align-items-center">
                                <img src="assets/images/net_fx.svg" className='w-100' alt="" />
                            </div>
                            <div className="col-xl-6">
                                <div className="fugu--default-content">
                                    <h2>NetFx</h2>
                                    <p>NetFX, part of the Network Chain ecosystem, offers a secure and cost-effective platform for forex trading. Its decentralized exchange ensures trustless transactions, while its user-friendly interface and integrated tools simplify trading for users. Join NetFX to access transparent and efficient forex trading within the Network Chain network.</p>
                                    <div className="fugu--btn-wrap">
                                        <a className="fugu--btn bg-blue" href="/net-fx">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
