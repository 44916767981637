import React from 'react'

export default function WhyChoos() {
    return (
        <>
            <div className="fugu--content-section pt-5" style={{ backgroundImage: `url('assets/images/network-bg.png')`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "50% 50%" }}>
                <div className="container">
                    <div className="fugu--content-top">
                        <div className="row">
                            <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                                <div className="fugu--content-thumb">
                                    <img className="wow fadeInUpX w-100" data-wow-delay=".10s" src="assets/images/why-choose-us-network-studio.png" alt="" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUpX' }} />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="fugu--default-content">
                                    <h2>Why Choose us?</h2>
                                    <div className="fugu--meta">
                                        <ul>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />Empowering network marketers worldwide with secure, transparent transactions on Network Chain.
                                            </li>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />
                                                Experience the future of network marketing with Network Chain's cutting-edge blockchain technology.
                                            </li>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />Join the global network marketing community on Network Chain for seamless, borderless transactions.
                                            </li>
                                            <li><img src="assets/images/svg2/check.svg" alt="" />Network Chain: Redefining trust and transparency in network marketing through blockchain innovation
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
