import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import Hero from "./Hero";
import Web3 from "./Web3";
import Advantage from "./Advantage";
import Decentralization from "./Decentralization";
import Allocation from "./Allocation";
import Ecoosystem from "./Ecoosystem";
import Advocates from "./Advocates";
import Subscribe from "./Subscribe";
import NetworkCommunity from "./NetworkCommunity";
import Networker from "./Networker";
import Tokenomics from "../Comman/Tokenomics";
import Roadmap from "../Comman/Roadmap";
import SocialMedia from "../Comman/SocialMedia";

export default function Index() {
  return (
    <>
      <Header />
      <Hero />
      <Web3 />
      <Advantage />
      <Decentralization />
      <Tokenomics />
      <Allocation />
      <NetworkCommunity />
      <Roadmap />
      <Ecoosystem />
      <Advocates />
      <Networker />
      <SocialMedia/>
      {/* <Subscribe /> */}
      <Footer />
    </>
  );
}
