import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Infrastructure from './Infrastructure'
import Whitepaper from './Whitepaper'
import PlatformHero from './PlatformHero'

export default function Platform() {
    return (
        <>
            <Header />
            <PlatformHero/>
            <Infrastructure />
            <Whitepaper/>
            <Footer />
        </>
    )
}
