import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function ComingSoon() {
    return (
        <>
            <Header />
            <div
                className="fugu--hero-section" style={{ backgroundImage: `url(./assets/images/comming-soon.png)` }}
            >
                <div className="container d-flex justify-content-center " style={{height:"70vh"}}>
                    <div className="row justify-content-center align-content-center">
                        <div className="col-lg-12">
                            <div className="fugu--hero-conten fugu--hero-content3">
                                <h1
                                    className="wow fadeInUpX text-white text-center fa-1"
                                    data-wow-delay="0s"
                                    style={{
                                        visibility: "visible",
                                        animationDelay: "0s",
                                        animationName: "fadeInUpX",
                                    }}
                                >
                                    Coming Soon
                                </h1>
                                <div className="">
                                    <div
                                        className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX text-center"
                                        data-wow-delay="0.25s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.25s",
                                            animationName: "fadeInUpX",
                                        }}
                                    >
                                        <a className="fugu--btn bg-blue active rounded-pill" href="https://networkchain.gitbook.io/developer-documentation" target="_blank">
                                            Documentation
                                        </a>
                                        <a className="fugu--btn bg-blue rounded-pill" href="https://networkchain.gitbook.io/networkchain/" target="_blank">
                                            Whitepaper
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
