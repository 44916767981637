import React from 'react'

export default function SocialMedia() {
    return (
        <>
            <div class="hero" style={{ backgroundImage: `url('assets/images/circle_bg.png')`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "50% 50%" }}>
                <h2 className='text-center'>Join Networkchain Community </h2>
                <br />
                <div class="social-links d-flex flex-wrap">
                    <a href=""><i class="fab fa-facebook-f"></i></a>
                    <a href="https://www.instagram.com/netchainofficial/" target='_blank'><i class="fab fa-instagram"></i></a>
                    <a href="https://twitter.com/networkchainn" target='_blank'><i class="fab fa-twitter"></i></a>
                    <a href=""><i class="fab fa-github"></i></a>
                    <a href="https://medium.com/@netchainofficial" target='_blnak'><i class="fab fa-medium"></i></a>
                </div>
                <a className="fugu--btn bg-blue active rounded-pill mt-5" href="/community" target="_blank">
                    Learn More
                </a>
            </div>
        </>
    )
}
