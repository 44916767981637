import React from "react";

export default function Decentralization() {
  return (
    <>
      <div className="fugu--inner-section dark-version" style={{backgroundImage:`url('assets/images/network-bg.png')`, backgroundRepeat:"no-repeat", backgroundSize:"cover",backgroundPosition:"50% 50%"}}>
        <div className="container">
          <div className="fugu--breadcrumbs-section">
            <div className="fugu--breadcrumbs-dat center-content text-start">
              <p className="text-white fs-2">Decentralization</p>
              <h1 className="text-white">Built <span className="text-orange">for the people.</span> <br /> Led by the community.</h1>
              <h3 className="text-white">
                Putting power where it belongs - in the hands of the people.</h3>
            </div>
          </div>
          <div className="fugu--blog-top-section">
            <div className="fugu--blog-top-wrap">
              <div className="fugu--blog-wrap">
                <div className="fugu--blog-thumb">
                    <img src="assets/images/decentralization.png" className="w-100" alt="" />
                </div>
                <div className="fugu--blog-content">
                  <p>The advent of cryptocurrency has sparked significant transformations across various sectors, and network marketing enterprises have been quick to seize the opportunities presented. The decentralized architecture of digital currencies, alongside their widespread availability on a global scale, has ushered in a new era of possibilities for network marketing companies.</p>
                  <p>Within the cryptocurrency realm, blockchain technology has garnered immense popularity. However, despite its prevalence, many individuals within the network marketing sphere have encountered challenges in accessing user-friendly blockchain solutions. Addressing this gap, NetworkChain has pioneered the development of the inaugural blockchain tailored specifically for network marketing purposes.
                  </p>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div className="fugu--content-section pt-5">
        <div className="container">
          <div className="fugu--content-top">
            <div className="row">
              <div className="col-xl-6 d-flex justify-content-center align-items-center">
                <div className="fugu--content-thumb">
                  <img className="wow fadeInUpX" data-wow-delay=".10s" src="assets/images/this-innovative-blockchain.png" width="100%" alt="" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUpX' }} />
                 
                </div>
              </div>
              <div className="col-xl-6">
                <div className="fugu--default-content">
                  <p>This innovative blockchain platform holds the promise of revolutionizing the industry by providing network marketers with intuitive and accessible technology. Through NetworkChain's solution, individuals can harness the power of blockchain seamlessly, unlocking new avenues for growth and efficiency within the network marketing landscape.</p>
                  <p>By offering a dedicated blockchain platform designed to meet the unique needs of network marketing professionals, NetworkChain is poised to empower individuals worldwide, facilitating smoother transactions, enhanced security, and greater transparency within the network marketing ecosystem. This initiative marks a significant step forward in leveraging cryptocurrency and blockchain technology to drive positive change and innovation within the industry.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
