import React from 'react'

export default function Stepstoapply() {
    return (
        <>
            <div className="section bg-warning-200 fugu-section-padding3">
                <div className="container">
                    <div className="row">
                        <div className='col-12'>
                            <div className="py-4 text-start">
                                <h2>Getting Started</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-10 col-md-12">
                            <div className="fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <div className="fugu-iconbox-data2">
                                            <h4>Study the NET Grants Program Guidelines</h4>
                                            <p>It’s essential that you understand the criteria for participating in the program. Projects not following the guidelines significantly decrease their chances of being approved for a grant.</p>
                                            <h4 className="text-white"><span className='fs-5'></span><a href="/coming-soon" target='_blank'>Program guidelines</a></h4>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="fugu-iconbox-icon2">
                                            <img src="assets/images/ontent_2.png" width={300} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-10 col-md-12">
                            <div className="fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <div className="fugu-iconbox-data2">
                                            <h4>Explore the NET Ecosystem map</h4>
                                            <p>This will help you understand the existing development landscape of dApps and tools on NET, and discover market gaps. By targeting these gaps and proposing a market-fit product, you directly increase your chances of receiving a grant.</p>
                                            <h4 className="text-white"><span className='fs-5'></span><a href="/coming-soon" target='_blank'>Ecosystem map</a></h4>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="fugu-iconbox-icon2">
                                            <img src="assets/images/explore-the-net.png" width={300} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-10 col-md-12">
                            <div className="fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="fugu-iconbox-data2">
                                            <h4>Submit your application</h4>
                                            <div className="fugu--btn-wrap">
                                                <a className="fugu--btn bg-blue rounded-pill" href="/coming-soon">Apply here</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
