import React from 'react'

export default function Networker() {
    return (
        <>
            <div className="section fugu-section-padding bg-dark" style={{backgroundImage:`url('./assets/images/around_the_world.png')`}}>
                <div className="container">
                    <div className="row">
                        <div className='col-12'>
                            <div className="fugu-default-content">
                                <h3 className='text-white'>Networker</h3>
                                <h2 className='text-white'>Reaching all <span className='text-orange'>around the world.</span></h2>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-10 d-flex align-items-center">
                            <div className="fugu-default-content">
                                <p className='text-white'>
                                    Network Miner help expand and educate the Metis community and spread the word about the Metis network.
                                </p>
                                <div className="fugu-btn-wrap">
                                    <a className="fugu-btn" href="https://medium.com/@netchainofficial/become-a-miner-9860c1544dc1" target='_blank'>
                                       Become Miner
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
