import React from "react";

export default function Allocation() {
  return (
    <>
      <div className="section fugu-section-padding3 bg-black">
        <div className="container">
          <div className="fugu-gallery-wrap3">
            <div className="row">
              <div className="col-lg-12">
                <div className="fugu-gallery-data4 text-center">
                  <h2 className="text-white">Network has committed 10,000 NBOX</h2>
                  <h4 className="text-white">Check out what's the current status of the committed allocation</h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div
                  className="fugu-gallery-thumb wow fadeInUpX"
                  style={{ visibility: "visible", animationName: "fadeInUpX" }}
                >
                  <img src="assets/images/allocation.png" className="w-100" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
