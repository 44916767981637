import React from 'react'

export default function NetsoftHero() {
    return (
        <>
            <div className="fugu--hero-section" style={{backgroundImage:`url('assets/images/network-bg.png')`, backgroundRepeat:"no-repeat", backgroundSize:"cover",backgroundPosition:"50% 50%"}}>
                <div id="fugu--counter" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="fugu--hero-content">
                                <h1 className="wow fadeInUpX" data-wow-delay="0s">Empowering Innovation and Technology Advancement</h1>
                                <div className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX" data-wow-delay="0.20s">
                                    <a className="fugu--btn bg-gray active" href="/coming-soon">Visit</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <img src="assets/images/net_soft_phn.png" className='w-100' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
