import React from 'react'

export default function Tokenomics() {
    return (
        <>
            <div className="fugu--vision-section py-5">
                <div className="container">
                    <div className="row py-5 align-items-center">
                        <div className="col-xl-6 col-lg-6 d-flex align-items-center">
                            <div className="fugu--default-content content-sm">
                                <h2 className="text-white">Token Details</h2>
                                <ul>
                                    <li>
                                        <h4 className="text-white"><span className='fs-5'>Name: </span> Network Coin</h4>
                                    </li>
                                    <li>
                                        <h4 className="text-white"><span className='fs-5'>Ticker Symbol: </span> NET</h4>
                                    </li>
                                    <li>
                                        <h4 className="text-white"><span className='fs-5'>Total Supply: </span> 2,21,00,000 NET</h4>
                                    </li>
                                    <li>
                                        <h4 className="text-white"><span className='fs-5'>Decimal: </span> 18</h4>
                                    </li>
                                    <li>
                                        <h4 className="text-white"><span className='fs-5'>Contract Adderss: </span><a href="https://bscscan.com/address/0x102054c91c93b0d30f982f28f224896d3d844b99" target='_blank'>0x102....44b99</a></h4>
                                    </li>
                                </ul>
                                <div className="fugu--btn-wrap">
                                    <a className="fugu--btn bg-blue" href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x102054C91C93B0D30f982f28f224896D3d844b99" target='_blnak'>Buy Token</a>
                                </div>
                            </div>
                        </div>
                        <div className=" col-xl-6 col-lg-6 ">
                            <img src="assets/images/tokenomic_png.png" className='w-100' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
