import React from 'react'

export default function Redacted() {
    return (
        <>
            <div className="fugu--about-section2"
                style={{backgroundImage:`url('assets/images/network-bg.png')`, backgroundRepeat:"no-repeat", backgroundSize:"cover",backgroundPosition:"50% 50%"}}
            >
                <div className="container">
                    <div className="fugu--section-title">
                        <div className="fugu--hero-content">
                            <h1>About our company</h1>
                            <p>An NFT is a digital asset that represents real-world objects like art, music, in-game items and videos. They are bought and sold online, frequently with cryptocurrency, and they are generally encoded with the same underlying software as many cryptos.</p>
                        </div>
                    </div>
                    <div className="fugu--thumb-thumb2 wow fadeInUpX" data-wow-delay=".10s" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUpX' }}>
                        <img src="assets/images/all-img/about2/about-thumb.jpg" alt="" />
                    </div>
                </div>
            </div>

        </>
    )
}
