import React from 'react'

export default function NetEx() {
    return (
        <>
            <div className="fugu--content-section pt-5">
                <div className="container">
                    <div className="fugu--content-top">
                        <div className="row align-items-center">
                            <div className="col-xl-6 d-flex justify-content-center align-items-center order-xl-2">
                                <img src="assets/images/net_ex.svg" className='w-100' alt="" />
                            </div>
                            <div className="col-xl-6">
                                <div className="fugu--default-content">
                                    <h2>NetEx</h2>
                                    <p>Experience security and trustworthiness in the crypto world with our centralized exchange platform.</p>
                                    <p>Fugu offers one of the largest and safest centralized exchange platforms, ensuring peace of mind for users. Our crypto wallets boast a comprehensive suite of security features while remaining user-friendly and cost-effective.</p>
                                    <p>Enjoy cost-effective and secure transactions with our centralized exchange platform.</p>
                                    <p>Our platform offers a seamless and private mode of transaction, ensuring your funds are safe and your privacy is protected</p>
                                    <p>Smooth currency exchanges with decentralization at its core.
                                    Our decentralized currency exchange ensures smooth and secure transactions, putting you in control of your assets.</p>
                                </div>
                                <div className="fugu--btn-wrap">
                                    <a className="fugu--btn bg-blue" href="/net-ex">Learn more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
