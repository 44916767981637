import React from "react";

export default function CareerHero() {
  return (
    <>
      <div className="fugu-breadcrumb-section">
        <div className="container">
          <div className="breadcrumbs">
            <h1
              className="wow fadeInUpX"
              data-wow-delay="0s"
              style={{
                visibility: "visible",
                animationDelay: "0s",
                animationName: "fadeInUpX",
              }}
            >
              Careers
            </h1>
            <nav
              aria-label="breadcrumb"
              className="wow fadeInUpX"
              data-wow-delay="0.15s"
              style={{
                visibility: "visible",
                animationDelay: "0.15s",
                animationName: "fadeInUpX",
              }}
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {" "}
                  Careers
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="fugu-shape8">
          <img src="assets/images/shape/shape7.png" alt="" />
        </div>
        <div className="fugu-shape9">
          <img src="assets/images/shape/shape8.png" alt="" />
        </div>
      </div>
    </>
  );
}
