import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import NetworkChainHero from './NetworkChainHero'
import NetworkChainFeature from './NetworkChainFeature'
import WhyChoosNetworkChain from './WhyChoosNetworkChain'

export default function NetworkChain() {
    return (
        <>
            <Header />
            <NetworkChainHero />
            <NetworkChainFeature /> 
            <WhyChoosNetworkChain />
            <Footer />
        </>
    )
}
