import React from "react";

export default function Advocates() {
  return (
    <>
      <div className="section fugu-section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-1 col-lg-6 order-lg-2">
              <div
                className="fugu-video-thumb wow fadeInUpX"
                data-wow-delay=".10s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.1s",
                  animationName: "fadeInUpX",
                }}
              >
                <img src="assets/images/enabling-global-trade.png" className="w-100" alt="" />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-10 d-flex align-items-center">
              <div className="fugu-default-content">
                <h3>The Impact of Cryptocurrency on Network Marketing</h3>
                <h2>Enabling Global Trade</h2>
                <p>Cryptocurrency has revolutionized global commerce within the network marketing sector by simplifying cross-border transactions. Unlike conventional banking systems, which are hampered by geographical and regulatory boundaries, cryptocurrencies offer a frictionless method for transferring funds across the globe. This newfound flexibility empowers network marketing enterprises to broaden their international reach and explore new markets with ease.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
