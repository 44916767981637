import React from 'react'

export default function NetworkCommunity() {
    return (
        <>
            <div className="fugu--vision-section py-5">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-xl-6 col-lg-6 d-flex align-items-center">
                            <div className="fugu--default-content content-sm">
                                <h3 className="text-white">
                                    Join To Network chain create your
                                    wealth with network community
                                </h3>
                                <p>Upon joining NetworkChain and acquiring Network Box Tokens (NBOX), participants who hold their tokens for a minimum of three months gain access to a comprehensive suite of benefits and support services tailored to their needs. Here's how NetworkChain provides assistance to its members:</p>
                                <div className="fugu--btn-wrap">
                                    <a className="fugu--btn bg-gradient" href="/net-box">
                                        Learn More
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className=" col-xl-6 col-lg-6 ">
                            <img src="assets/images/join_to_network_chain01.png" className='w-100' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
