import React from "react";

export default function Positions() {
  return (
    <>
      <div className="section fugu-section-padding3">
        <div className="container">
          <div className="fugu-section-title2">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <h2>Current available positions at fugu</h2>
              </div>
              <div className="col-xl-5 col-lg-6 offset-xl-2 d-flex align-items-center">
                <p>
                  Our goal is to provide our customers with the best service or
                  product at the best possible market price without any kind of
                  compromising quality. It is the core mission of our company.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-6 col-lg-6">
              <div
                className="fugu-career-card wow fadeInUpX"
                data-wow-delay="0s"
                style={{
                  visibility: "visible",
                  animationDelay: "0s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu-career-data">
                  <h4>UI/UX and Product Designer</h4>
                  <p>
                    It has survived not only five centuries, but also a the leap
                    into electronic typesetting
                  </p>
                </div>
                <div className="fugu-career-time">
                  <ul>
                    <li>
                      <img src="assets/images/svg/clock.svg" alt="" />
                      Full Time
                    </li>
                    <li>
                      <img src="assets/images/svg/map.svg" alt="" />
                      London, UK
                    </li>
                    <li>
                      <img src="assets/images/svg/cash.svg" alt="" />
                      $25k-$40k
                    </li>
                  </ul>
                </div>
                <a className="fugu-icon-btn" href="single-career.html">
                  Apply Now{" "}
                  <img src="assets/images/svg/arrow-black-right.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-6 col-lg-6">
              <div
                className="fugu-career-card wow fadeInUpX"
                data-wow-delay=".10s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.1s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu-career-data">
                  <h4>Jr Frontend Developer</h4>
                  <p>
                    It has survived not only five centuries, but also a the leap
                    into electronic typesetting
                  </p>
                </div>
                <div className="fugu-career-time">
                  <ul>
                    <li>
                      <img src="assets/images/svg/clock.svg" alt="" />
                      Full Time
                    </li>
                    <li>
                      <img src="assets/images/svg/map.svg" alt="" />
                      London, UK
                    </li>
                    <li>
                      <img src="assets/images/svg/cash.svg" alt="" />
                      $25k-$40k
                    </li>
                  </ul>
                </div>
                <a className="fugu-icon-btn" href="single-career.html">
                  Apply Now{" "}
                  <img src="assets/images/svg/arrow-black-right.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-6 col-lg-6">
              <div
                className="fugu-career-card wow fadeInUpX"
                data-wow-delay=".20s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu-career-data">
                  <h4>Senior Office Manager</h4>
                  <p>
                    It has survived not only five centuries, but also a the leap
                    into electronic typesetting
                  </p>
                </div>
                <div className="fugu-career-time">
                  <ul>
                    <li>
                      <img src="assets/images/svg/clock.svg" alt="" />
                      Full Time
                    </li>
                    <li>
                      <img src="assets/images/svg/map.svg" alt="" />
                      London, UK
                    </li>
                    <li>
                      <img src="assets/images/svg/cash.svg" alt="" />
                      $25k-$40k
                    </li>
                  </ul>
                </div>
                <a className="fugu-icon-btn" href="single-career.html">
                  Apply Now{" "}
                  <img src="assets/images/svg/arrow-black-right.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-6 col-lg-6">
              <div
                className="fugu-career-card wow fadeInUpX"
                data-wow-delay=".30s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.3s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu-career-data">
                  <h4>SEO Specialist</h4>
                  <p>
                    It has survived not only five centuries, but also a the leap
                    into electronic typesetting
                  </p>
                </div>
                <div className="fugu-career-time">
                  <ul>
                    <li>
                      <img src="assets/images/svg/clock.svg" alt="" />
                      Full Time
                    </li>
                    <li>
                      <img src="assets/images/svg/map.svg" alt="" />
                      London, UK
                    </li>
                    <li>
                      <img src="assets/images/svg/cash.svg" alt="" />
                      $25k-$40k
                    </li>
                  </ul>
                </div>
                <a className="fugu-icon-btn" href="single-career.html">
                  Apply Now{" "}
                  <img src="assets/images/svg/arrow-black-right.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-6 col-lg-6">
              <div
                className="fugu-career-card wow fadeInUpX"
                data-wow-delay=".40s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu-career-data">
                  <h4>Marketing Executive</h4>
                  <p>
                    It has survived not only five centuries, but also a the leap
                    into electronic typesetting
                  </p>
                </div>
                <div className="fugu-career-time">
                  <ul>
                    <li>
                      <img src="assets/images/svg/clock.svg" alt="" />
                      Full Time
                    </li>
                    <li>
                      <img src="assets/images/svg/map.svg" alt="" />
                      London, UK
                    </li>
                    <li>
                      <img src="assets/images/svg/cash.svg" alt="" />
                      $25k-$40k
                    </li>
                  </ul>
                </div>
                <a className="fugu-icon-btn" href="single-career.html">
                  Apply Now{" "}
                  <img src="assets/images/svg/arrow-black-right.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-6 col-lg-6">
              <div
                className="fugu-career-card wow fadeInUpX"
                data-wow-delay=".50s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInUpX",
                }}
              >
                <div className="fugu-career-data">
                  <h4>Senior Backend Developer</h4>
                  <p>
                    It has survived not only five centuries, but also a the leap
                    into electronic typesetting
                  </p>
                </div>
                <div className="fugu-career-time">
                  <ul>
                    <li>
                      <img src="assets/images/svg/clock.svg" alt="" />
                      Full Time
                    </li>
                    <li>
                      <img src="assets/images/svg/map.svg" alt="" />
                      London, UK
                    </li>
                    <li>
                      <img src="assets/images/svg/cash.svg" alt="" />
                      $25k-$40k
                    </li>
                  </ul>
                </div>
                <a className="fugu-icon-btn" href="single-career.html">
                  Apply Now{" "}
                  <img src="assets/images/svg/arrow-black-right.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
