import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import DeveloperHero from './DeveloperHero'
import GettingStarted from './GettingStarted'
import News from '../Comman/News'
import Whitepaper from './Whitepaper'
import Stepstoapply from './Stepstoapply'

export default function Developer() {
    return (
        <>
            <Header />
            <DeveloperHero />
            <GettingStarted />
            {/* <News /> */}
            <Stepstoapply/>
            <Whitepaper />
            <Footer />
        </>
    )
}
