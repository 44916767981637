import React from "react";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import CareerHero from "./CareerHero";
import WorkWithUs from "./WorkWithUs";
import Positions from "./Positions";

export default function Career() {
  return (
    <>
      <Header />
      <CareerHero />
      <WorkWithUs />
      <Positions />
      <Footer />
    </>
  );
}
