import React from 'react'

export default function PlatformHero() {
    return (
        <>
            <div
                className="fugu--hero-section3"
                style={{
                    backgroundImage: "url(assets/images/all-img/v5/hero-bg2.png)",
                }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">

                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div
                                className="video-text"
                                style={{
                                    visibility: "visible",
                                    animationName: "fadeInUpX",
                                    height: "500px",
                                }}
                            >
                                <div className="pt-5">
                                    <div className="fugu--hero-conten fugu--hero-content3">
                                        <h1
                                            className="wow fadeInUpX text-white"
                                            data-wow-delay="0s"
                                            style={{
                                                visibility: "visible",
                                                animationDelay: "0s",
                                                animationName: "fadeInUpX",
                                            }}
                                        >
                                            Metis Network
                                        </h1>
                                    </div>
                                    <p
                                        className="wow fadeInUpX text-white text-start fs-3"
                                        data-wow-delay="0.15s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.15s",
                                            animationName: "fadeInUpX",
                                        }}
                                    >
                                        Ethereum Smart L2 for a <br /> boundless better economy

                                    </p>
                                    <div
                                        className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX"
                                        data-wow-delay="0.25s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.25s",
                                            animationName: "fadeInUpX",
                                        }}
                                    >
                                        <a className="fugu--btn bg-blue active" href="#">
                                            Build on Network
                                        </a>
                                        <a className="fugu--btn bg-blue active" href="#">
                                            Read Whitepaper
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <video muted autoPlay loop id="myVideo" className="video-2">
                                <source src="assets/images/hero.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
                <div className="fugu--circle-shape circle-one">
                    <img src="assets/images/all-img/shapes-round.png" alt="" />
                    <div className="waves wave-1" />
                </div>
                <div className="fugu--circle-shape circle-two">
                    <img src="assets/images/all-img/shapes-round.png" alt="" />
                    <div className="waves wave-1" />
                </div>
            </div>
        </>
    )
}
