import React from "react";

export default function CEGHero() {
    return (
        <>
            <div
                className="fugu--hero-section3"
                style={{
                    backgroundImage: `url('assets/images/network-bg.png')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "50% 50%",
                }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div
                                className="video-text"
                                style={{
                                    visibility: "visible",
                                    animationName: "fadeInUpX",
                                    height: "500px",
                                }}
                            >

                                <div className="fugu--hero-conten fugu--hero-content3">
                                    <p className="text-white mb-0">Announcing</p>
                                    <h5
                                        className="wow fadeInUpX text-white mb-0"
                                        data-wow-delay="0s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0s",
                                            animationName: "fadeInUpX",
                                        }}
                                    >
                                        NCT
                                    </h5>
                                    <h3 className="text-white">
                                        Community Ecosystem <br /> Governance
                                    </h3>
                                </div>
                                <p
                                    className="wow fadeInUpX text-white text-start fw-bold fs-5"
                                    data-wow-delay="0.15s"
                                    style={{
                                        visibility: "visible",
                                        animationDelay: "0.15s",
                                        animationName: "fadeInUpX",
                                    }}
                                >
                                    Putting the power in your <br /> hands – where it belongs.
                                </p>
                                <a
                                    className="fugu--btn bg-blue rounded-pill active"
                                    href="/coming-soon"
                                >
                                    Start Voting
                                </a>
                            </div>
                            <video muted autoPlay loop id="myVideo" className="video-2 d-lg-block d-md-block d-sm-none d-none">
                                <source src="assets/images/network_video.mp4" type="video/mp4" />
                            </video>
                            <video muted autoPlay loop id="myVideo" className="video-2 d-lg-none d-md-none d-sm-block d-block">
                                <source src="assets/images/network_video_mobile.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
