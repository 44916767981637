import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import NboxHero from './NboxHero'
import NboxFeature from './NboxFeature'
import NboxAddditonalPoint from './NboxAddditonalPoint'
import WhyChoosNbox from './WhyChoosNbox'

export default function Nbox() {
  return (
    <>
      <Header />
      <NboxHero />
      <NboxFeature />
      {/* <WhyChoosNbox /> */}
      <WhyChoosNbox/>
      <NboxAddditonalPoint />
      <Footer />
    </>
  )
}
