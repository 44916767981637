import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import NetsoftHero from './NetsoftHero'
import NetsoftFeature from './NetsoftFeature'
import WhyChoosNetsoft from './WhyChoosNetsoft'

export default function Netsoft() {
    return (
        <>
            <Header />
            <NetsoftHero />
            <NetsoftFeature /> 
            <WhyChoosNetsoft />
            <Footer />
        </>
    )
}
