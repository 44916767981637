import React from 'react'

export default function GettingStarted() {
    return (
        <>
            <div className="section bg-warning-200 fugu-section-padding3">
                <div className="container">
                    <div className="row">
                        <div className='col-12'>
                            <div className="py-4 text-start">
                                <h2>Getting Started</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-icon2">
                                    <img src="assets/images/connection_details.png" alt="" />
                                </div>
                                <div className="fugu-iconbox-data2">
                                    <h4>Connection Details</h4>
                                    <p>Get all the necessary info like RPC Endpoint, Chain ID etc.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0.20s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-icon2">
                                    <img src="assets/images/test_tokens.png" alt="" />
                                </div>
                                <div className="fugu-iconbox-data2">
                                    <h4>Test Tokens</h4>
                                    <p>Get the test tokens for Networkchain testnet.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0.30s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-icon2">
                                    <img src="assets/images/network_explorer.png" alt="" />
                                </div>
                                <div className="fugu-iconbox-data2">
                                    <h4>Network Explorer</h4>
                                    <p>See the transaction details, network stats, etc.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
