import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import QAHero from './QAHero'
import Faq from './Faq'

export default function QA() {
  return (
    <>
      <Header />
      <QAHero />
      <Faq/>
      <Footer />
    </>
  )
}
