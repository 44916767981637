import React from 'react'

export default function MineNetworkHero() {
    return (
        <>
            <div className="fugu--hero-section" style={{backgroundImage:`url('assets/images/network-bg.png')`, backgroundRepeat:"no-repeat", backgroundSize:"cover",backgroundPosition:"50% 50%"}}>
                <div id="fugu--counter" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="fugu--hero-content">
                                <h1 className="wow fadeInUpX" data-wow-delay="0s">Empowering Networkers Through Affiliate Programs</h1>
                                <p className="wow fadeInUpX" data-wow-delay="0.10s">Mine Your Network is a unique affiliate-based program within the Network Chain ecosystem, designed to empower users to participate actively in the network while earning rewards and incentives. This innovative program incentivizes users to build and grow their network, fostering community engagement and driving adoption of the Network Chain platform.</p>
                                <div className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX" data-wow-delay="0.20s">
                                    <a className="fugu--btn bg-gray active" href="/coming-soon">Download</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <img src="assets/images/mine_ur_network_phn.png" className='w-100' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
