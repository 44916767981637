import React from 'react'

export default function WhatWho() {
    return (
        <>
            <div className="fugu--vision-section py-5">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-xl-6 col-lg-6 d-flex align-items-center">
                            <div className="fugu--default-content content-sm">
                                <h3 className="text-white">
                                    Powering the decentralization
                                </h3>
                                <p>Use Network to vote for protocol improvement proposals (PIPs). Network has implemented a stake-to-vote governance model requiring users to stake Network for voting. Read More..</p>
                                <div className="fugu--btn-wrap">
                                    <a className="fugu--btn bg-gradient" href="/coming-soon">
                                        See Proposals
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className=" col-xl-6 col-lg-6 ">
                            <img src="assets/images/join_to_network_chain.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
