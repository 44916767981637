import React from 'react'

export default function Infrastructure() {
    return (
        <>
            <div className="fugu--artwork-section">
                <div id="fugu--counter2" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="fugu--default-content content-black">
                                <h4>Advantages</h4>
                                <h2>Everything you want. All in one.</h2>
                                <h3>Permissionless. Frictionless. Boundless.</h3>
                                <div className="fugu--counter-wra fugu--counter-wrap2 mt-5">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="fugu--counter-data d-flex justify-content-around">
                                                <img src="./assets/images/icon/decentralized.png" width="50" height="50" alt="" />
                                                <p>
                                                    Decentralized <br /> Ecosytem Governanc
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="fugu--counter-data d-flex justify-content-around">
                                                <img src="./assets/images/icon/secure_network.png" width="50" height="50" alt="" />
                                                <p>Secure Network</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="fugu--counter-data d-flex justify-content-around">
                                                <img src="./assets/images/icon/scalable.png" width="50" height="50" alt="" />
                                                <p>
                                                    Scalable <br /> Infrastructure
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="fugu--counter-data d-flex justify-content-around">
                                                <img src="./assets/images/icon/simple_integration.png" width="50" height="50" alt="" />
                                                <p>
                                                    Simple <br />
                                                    Integration
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-lg-3">
                                            <div className="fugu--counter-data d-flex justify-content-around">
                                                <img src="./assets/images/icon/affordable.png" width="50" height="50" alt="" />
                                                <p>
                                                    Affordable <br /> On-Chain Storage
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="fugu--counter-data d-flex justify-content-around">
                                                <img src="./assets/images/icon/fast_transactions.png" width="50" height="50" alt="" />
                                                <p>
                                                    Fast <br /> Transactions
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="fugu--counter-data d-flex justify-content-around">
                                                <img src="./assets/images/icon/low_fees.png" width="50" height="50" alt="" />
                                                <p>
                                                    Low <br />
                                                    Gas Fees
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
