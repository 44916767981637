import React from 'react'

export default function CommunityApp() {
    return (
        <>
            <div className="fugu--feature-section fugu--section-padding2">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-md-6">
                            <div
                                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                                data-wow-delay="0s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0s",
                                    animationName: "fadeInUpX",
                                }}
                            >
                                <img src="assets/images/social/telegram.svg" width="200" alt="" />
                                <div className="fugu--iconbox-data">
                                    <h4>Telegram</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <a href="https://twitter.com/networkchainn" target='_blank'>
                                <div
                                    className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                                    data-wow-delay=".15s"
                                    style={{
                                        visibility: "visible",
                                        animationDelay: "0.15s",
                                        animationName: "fadeInUpX",
                                    }}
                                >
                                    <img src="assets/images/social/twt.svg" width="200" alt="" />
                                    <div className="fugu--iconbox-data">
                                        <h4>Twitter</h4>

                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div
                                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                                data-wow-delay=".15s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.15s",
                                    animationName: "fadeInUpX",
                                }}
                            >
                                <img src="assets/images/social/github.svg" width="200" alt="" />
                                <div className="fugu--iconbox-data">
                                    <h4>Github</h4>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div
                                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                                data-wow-delay="0s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0s",
                                    animationName: "fadeInUpX",
                                }}
                            >
                                <img src="assets/images/social/tiktok.svg" width="200" alt="" />
                                <div className="fugu--iconbox-data">
                                    <h4>TikTok</h4>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div
                                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                                data-wow-delay=".15s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.15s",
                                    animationName: "fadeInUpX",
                                }}
                            >
                                <img src="assets/images/social/event.svg" width="200" alt="" />
                                <div className="fugu--iconbox-data">
                                    <h4>Events</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <a href="https://medium.com/@netchainofficial" target='_blank'>
                                <div
                                    className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                                    data-wow-delay=".15s"
                                    style={{
                                        visibility: "visible",
                                        animationDelay: "0.15s",
                                        animationName: "fadeInUpX",
                                    }}
                                >
                                    <img src="assets/images/social/meduim.svg" width="200" alt="" />
                                    <div className="fugu--iconbox-data">
                                        <h4>Medium</h4>

                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div
                                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                                data-wow-delay="0s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0s",
                                    animationName: "fadeInUpX",
                                }}
                            >
                                <img src="assets/images/social/youtube.svg" width="200" alt="" />
                                <div className="fugu--iconbox-data">
                                    <h4>Youtube</h4>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div
                                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                                data-wow-delay=".15s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.15s",
                                    animationName: "fadeInUpX",
                                }}
                            >
                                <img src="assets/images/social/reddit.svg" width="200" alt="" />
                                <div className="fugu--iconbox-data">
                                    <h4>Reddit</h4>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div
                                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                                data-wow-delay=".15s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.15s",
                                    animationName: "fadeInUpX",
                                }}
                            >
                                <img src="assets/images/social/fb.svg" width="200" alt="" />
                                <div className="fugu--iconbox-data">
                                    <h4>Facebook</h4>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <a href="https://www.instagram.com/netchainofficial/" target='_blank'>
                                <div
                                    className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                                    data-wow-delay=".15s"
                                    style={{
                                        visibility: "visible",
                                        animationDelay: "0.15s",
                                        animationName: "fadeInUpX",
                                    }}
                                >
                                    <img src="assets/images/social/insta.svg" width="200" alt="" />
                                    <div className="fugu--iconbox-data">
                                        <h4>Instagram</h4>

                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div
                                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                                data-wow-delay=".15s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.15s",
                                    animationName: "fadeInUpX",
                                }}
                            >
                                <img src="assets/images/social/discord.svg" width="200" alt="" />
                                <div className="fugu--iconbox-data">
                                    <h4>Discord</h4>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div
                                className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX"
                                data-wow-delay=".15s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.15s",
                                    animationName: "fadeInUpX",
                                }}
                            >
                                <img src="assets/images/social/linkdin.svg" width="200" alt="" />
                                <div className="fugu--iconbox-data">
                                    <h4>Linkedin</h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
