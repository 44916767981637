import React from 'react'

export default function StartBuilding() {
    return (
        <>
            <div className="fugu--feature-section fugu--section-padding2">
                <div className="container">
                    <div className="fugu--section-title2">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="fugu--default-content content-sm">
                                    <h2>Unlocking new pathways for creative commerce through the power of NET.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-md-6">
                            <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <img src="assets/images/safe_and_secure.png" width={100} alt="" />
                                <div className="fugu--iconbox-data">
                                    <h4>Safe and secure</h4>
                                    <p>NETs are safe when bought or sold on reputable exchanges, &amp; they cannot be stolen if users properly secure.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay=".15s" style={{ visibility: 'visible', animationDelay: '0.15s', animationName: 'fadeInUpX' }}>
                                <img src="assets/images/complete_transparency.png" width={100} alt="" />
                                <div className="fugu--iconbox-data">
                                    <h4>Complete transparency</h4>
                                    <p>Transparency in a process involves it being completely visible and open to scrutiny, so that it's clear.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay=".25s" style={{ visibility: 'visible', animationDelay: '0.25s', animationName: 'fadeInUpX' }}>
                                <img src="assets/images/creative_team.png" width={100} alt="" />
                                <div className="fugu--iconbox-data">
                                    <h4>Creative team</h4>
                                    <p>Netowrk has creative artists, painters, and designers working tirelessly on NET art all over the world.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
