import React from 'react'

export default function GetStarted() {
    return (
        <>
            <div
                className="fugu--hero-section3"
                style={{ backgroundImage: `url('assets/images/network-bg.png')`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "50% 50%" }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div
                                className="video-text"
                                style={{
                                    visibility: "visible",
                                    animationName: "fadeInUpX",
                                    height: "500px",
                                }}
                            >
                                <div className="pt-5">
                                    <div className="fugu--hero-conten fugu--hero-content3">
                                        <h1
                                            className="wow fadeInUpX text-white"
                                            data-wow-delay="0s"
                                            style={{
                                                visibility: "visible",
                                                animationDelay: "0s",
                                                animationName: "fadeInUpX",
                                            }}
                                        >
                                            Welcome
                                        </h1>
                                    </div>
                                    <p
                                        className="wow fadeInUpX text-white text-start fs-3"
                                        data-wow-delay="0.15s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.15s",
                                            animationName: "fadeInUpX",
                                        }}
                                    >
                                        Innovative Solutions for Decentralized <br /> Networking: Explore Our Platform.
                                    </p>
                                    <div
                                        className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX"
                                        data-wow-delay="0.25s"
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.25s",
                                            animationName: "fadeInUpX",
                                        }}
                                    >
                                        <a className="fugu--btn bg-blue active" href="/mine-ur-network" target='_blank'>
                                            Mine-ur-Network
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <video muted autoPlay loop id="myVideo" className="video-2">
                                <source src="assets/images/hero.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
