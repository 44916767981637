import React from 'react'

export default function Architecture() {
    return (
        <>
            <div className="fugu--feature-section fugu--section-padding2">
                <div className="container">
                    <div className="fugu--section-title2">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="fugu--default-content content-sm">
                                    <h2>Our strength is to change the path of our creativity trade NFT</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 offset-lg-1">
                                <div className="fugu--default-content">
                                    <p>Nexto is a universal marketplace that has been working with NFTS Art since 2002. we are ready to change the world with NFTs!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-md-6">
                            <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="fugu--iconbox-thumb">
                                    <img src="assets/images/all-img/about2/feature1.png" alt="" />
                                </div>
                                <div className="fugu--iconbox-data">
                                    <h4>Safe and secure</h4>
                                    <p>NFTs are safe when bought or sold on reputable exchanges, &amp; they cannot be stolen if users properly secure.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay=".15s" style={{ visibility: 'visible', animationDelay: '0.15s', animationName: 'fadeInUpX' }}>
                                <div className="fugu--iconbox-thumb">
                                    <img src="assets/images/all-img/about2/feature2.png" alt="" />
                                </div>
                                <div className="fugu--iconbox-data">
                                    <h4>Complete transparency</h4>
                                    <p>Transparency in a process involves it being completely visible and open to scrutiny, so that it's clear.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX" data-wow-delay=".25s" style={{ visibility: 'visible', animationDelay: '0.25s', animationName: 'fadeInUpX' }}>
                                <div className="fugu--iconbox-thumb">
                                    <img src="assets/images/all-img/about2/feature3.png" alt="" />
                                </div>
                                <div className="fugu--iconbox-data">
                                    <h4>Creative team</h4>
                                    <p>Nexto has creative artists, painters, and designers working tirelessly on NFT art all over the world.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
