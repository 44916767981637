import React from 'react'

export default function NboxAddditonalPoint() {
    return (
        <>
            <div className="section bg-warning-200 fugu-section-padding3">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="py-4 text-start">
                                <h2>Addditonal point</h2>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-data2">
                                    <p>Governance Opportunities: NBOX token holders may have the opportunity to participate in platform governance, giving them a voice in shaping the future of the Network Chain ecosystem.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0.20s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-data2">
                                    <p>The concept of "limited supply and unlimited support" underscores the balance between scarcity and utility. While the limited token supply ensures exclusivity and potential for value appreciation, the unlimited support from Network Chain ensures that NBOX holders receive the assistance they need to leverage their tokens effectively.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0.30s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-data2">
                                    <p>The concept of "limited supply and unlimited support" underscores the balance between scarcity and utility. While the limited token supply ensures exclusivity and potential for value appreciation, the unlimited support from Network Chain ensures that NBOX holders receive the assistance they need to leverage their tokens effectively.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="fugu-iconbox-wrap2 wow fadeInUpX" data-wow-delay="0.30s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUpX' }}>
                                <div className="fugu-iconbox-data2">
                                    <p>In summary, NBOX's combination of limited token supply and unlimited support creates a compelling value proposition for network marketers. It offers the potential for value appreciation while providing access to a robust support system that empowers holders to succeed in their entrepreneurial endeavors within the network marketing industry.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
