import React from 'react'

export default function Feature() {
    return (
        <>
            <div className="fugu--content-section">
                <div className="container">
                    <div className="fugu--content-bottom">
                        <div className="row align-items-center pb-5">
                            <div className="col-xl-6 d-flex align-items-center">
                                <div className="fugu--content-thumb">
                                    <img className="wow fadeInLeft w-100" data-wow-delay=".10s" src="assets/images/network_studio_img.png" alt="" style={{ visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInLeft' }} />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="fugu--default-content">
                                    <h2>Network Studio</h2>
                                    <p>From technical guidance to community engagement, we offer comprehensive support services tailored to your needs.</p>
                                    <p>Our dedicated team provides round-the-clock assistance, ensuring you have the resources and expertise to succeed.</p>
                                    <p>Join our vibrant ecosystem and tap into a wealth of support, from marketing strategies to technical troubleshooting.</p>
                                    <p>With our integrated support network, you'll have everything you need to thrive in the ever-evolving landscape of blockchain technology and network marketing.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
