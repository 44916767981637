import React from 'react'

export default function GrantsHero() {
    return (
        <>
            <div className="fugu--about-hero-section dark-version vh-100" style={{ backgroundImage: `url('assets/images/network_studio_bg01png.png')`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "50% 50%" }}>
                <div className="container ">
                    <div className='row justify-content-lg-end justify-content-center justify-content-center mt-lg-0 mt-5'>
                        <div className='col-lg-7 col-md-10 col-sm-12 col-12'>
                            <h1 className='text-white text-center' >Startup Studio</h1>
                            <p className='text-white text-center'>Our startup studio program is dedicated to empowering innovators and entrepreneurs to turn their ideas into successful businesses. With access to a wealth of resources, mentorship, and funding opportunities, we provide the support needed to navigate the challenges of building a startup and bring innovative solutions to market</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
